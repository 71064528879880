/*jslint white: true, nomen: true */
/*global angular */
/*global ScrollMagic, attrs, elem */
(function() {
  'use strict';

  angular.module('app', [
      'angular-inview',
      // 'ui.bootstrap',
      'ngAnimate',
      'app.homepage',
      'app.common',
      'app.directives',
      //'app.microsite',
      'app.mainNav',
      'app.findYourHome',
      'app.forms',
      'app.floorplans',
      'app.ourFloorplans',
      'app.moveInReady',
      'app.homeownerTestimonials',
      'app.warrantyRequest',
      'app.netZeroHomes',
      'app.fullInterestList',
      'app.realtorExtranet',
      'app.realtorDashboard',
      'app.homecareTips',
      'app.ourTeam',
      'app.whereWeBuild',
      'app.homeGallery',
      'app.ourProcess',
      'app.portfolioCommunities',
      'app.newsAndPress',
      'app.buildingProcess',
      'app.reasonsToChooseUs',
      'app.newsDetail',
      'app.customFloorplans',
      'app.urbanBruBus',
      'app.excellenceInEverythingWeDo',
      'app.whyBuildGreen',
      'app.greenComponents',
      'app.stormwaterAndStandingWaterPolicy',
      'app.getInTouch',
      'app.coatDrive',
      'ngSanitize',
      'app.collection',
      'app.moveInReadyDetail',
      'app.residenceDetail',
      'ngSanitize',
      'modules.dataServices',
      'modules.device',
      'modules.priceStartingAt',
      'modules.laravelParameters',
      'modules.snapshotRange',
      'modules.trustHtml',
      'slickCarousel',
      'ngRoute',
      'LocalStorageModule'
    ])
    .config(['$interpolateProvider', function($interpolateProvider) {
      $interpolateProvider.startSymbol('<%');
      $interpolateProvider.endSymbol('%>');
    }])
    .config(['localStorageServiceProvider', function (localStorageServiceProvider) {
      localStorageServiceProvider
        .setPrefix('siteApp')
        .setStorageType('localStorage')
        .setNotify(true, true)
    }])
    .config([
      "$routeProvider",
      "$locationProvider",
      function($routeProvider, $locationProvider) {
        $locationProvider.html5Mode(false);
      }
    ])
.service('scrollMagic', ['$rootScope', function ($rootScope) {
  //$(document).ready(function(){
      var controller = new ScrollMagic.Controller();
          var scene = new ScrollMagic.Scene({
                  triggerElement: "#" + attrs.id,
                  triggerHook: 'onEnter',
                  offset: attrs.offset
              })
              //.addIndicators()
              .addTo(controller)
              .on("enter", function (e) {
                  if(!elem.hasClass('in')){
                      elem.addClass('in');
                      if(attrs.stagger !== undefined)
                      {
                        setTimeout(function(){
                          elem.find(attrs.stagger).velocity("transition."+attrs.transition,{stagger: attrs.speed});
                        },attrs.delay);
                      }
                  }
              })
              .on("leave", function(e){
                 if(attrs.toggle !== undefined) {
                  elem.removeClass('in');
                }
              });

              // this.scrollSpy = function(trigger, classname, triggerEvent, offsetnum){
              //     var scene = new ScrollMagic.Scene({
              //             triggerElement: '#'+trigger,
              //             triggerHook: triggerEvent,
              //             offset: offsetnum
              //         })
              //         .on("enter leave", function(e){
              //             var $target = $('#subnav li[target="' +  trigger + '"]');
              //             if(e.type == 'leave'){
              //                 if($target.prev() !== 0){
              //                     $target = $target.prev();
              //                 }
              //             }

              //             $target.addClass(classname).siblings().removeClass(classname);
              //             $rootScope.$broadcast('scrollspyTriggered',{url:$target.attr('target')});
              //         })
              //         //.addIndicators({name: trigger})
              //         .addTo(controller);
              // }
   // })

}])

// re renders masonry when tab is active
 .run(['$rootScope', '$timeout', function ($rootScope, $timeout) {
       $rootScope.renderGallery = function(attrs) {
          $timeout(function() {
             var $grid = $('.grid').imagesLoaded( function() {
                  // init Masonry after all images have loaded
                  $grid.masonry({
                      itemSelector: '.grid-item',
                      percentPosition: true
                  });
                });
          });
      };
}]);



  angular.module('app.homepage', []);
  //angular.module('app.microsite', ['ui.bootstrap', 'ngAnimate', 'duScroll']);
  angular.module('app.mainNav', []);
  angular.module('app.findYourHome', ['ui-rangeSlider']);
  angular.module('app.forms', ['ui.bootstrap', 'ngMessages', 'checklist-model']);
  angular.module('app.floorplans', ['ui.bootstrap', 'ngAnimate']);
  angular.module('app.ourFloorplans', []);
  angular.module('app.moveInReady', ['modules.trustAsResourceUrl']);
  angular.module('app.homeownerTestimonials', []);
  angular.module('app.warrantyRequest', []);
  angular.module('app.netZeroHomes', []);
  angular.module('app.fullInterestList', []);
  angular.module('app.realtorExtranet', []);
  angular.module('app.realtorDashboard', []);
  angular.module('app.ourTeam', []);
  angular.module('app.whereWeBuild',[]);
  angular.module('app.ourProcess', ['ngTouch']);
  angular.module('app.homecareTips', ['duScroll']);
  angular.module('app.homeGallery', ['ui.bootstrap', 'ngAnimate']);
  angular.module('app.portfolioCommunities', []);
  angular.module('app.buildingProcess', []);
  angular.module('app.reasonsToChooseUs', []);
  angular.module('app.whyBuildGreen', []);
  angular.module('app.greenComponents', []);
  angular.module('app.stormwaterAndStandingWaterPolicy', []);
  angular.module('app.getInTouch', []);
  angular.module('app.coatDrive', []);
  angular.module('app.newsAndPress', []);
  angular.module('app.newsDetail', []);
  angular.module('app.customFloorplans', ['youtube-embed']);
  angular.module('app.urbanBruBus', []);
  angular.module('app.excellenceInEverythingWeDo', []);
  angular.module('app.collection', []);
  angular.module('app.moveInReadyDetail', ['modules.laravelParameters']);
  angular.module('app.residenceDetail', []);
  angular.module('app.common', []);
  angular.module('app.directives', []);
}());
